<template>
  <section id="Puente" class="container form my-5" data-aos="fade-up">
    <div class="w-100 d-flex justify-content-start ms-8">
      <h2>
        <span style="color: #14993c"> Español </span>
      </h2>
    </div>
    <div class="text-center mb-5">
      <input
        class="form-control form-control-lg wet-form fw-bold text-center mb-2"
        type="text"
        v-model="dataES.title"
      />

      <input
        class="form-control wet-form text-center"
        type="text"
        v-model="dataES.subtitle"
      />
    </div>
    <div class="row align-items-center justify-content-center">
      <div class="col-12 col-md-4 mb-4">
        <div class="card shadow-sm puente-cards">
          <div class="regular-card">
            <div class="text-center">
              <img
                class="card-img img"
                :src="require(`../../assets/${dataES.cardOne.img}`)"
                alt="Imagen apartado emprendimientos"
              />
            </div>
            <div class="card-body">
              <input
                class="form-control form-control-lg wet-form card-title fw-bold mb-3 pb-2"
                type="text"
                v-model="dataES.cardOne.title"
              />
              <ckeditor
                :editor="editor"
                v-model="dataES.cardOne.content"
                :config="editorConfig"
              ></ckeditor>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-4 mb-4 green-card-container">
        <div class="card py-5 shadow-sm green-card text-center">
          <div>
            <div class="mb-3">
              <img
                class="card-img img"
                :src="require(`../../assets/${dataES.cardTwo.logo}`)"
                alt="Logo de wetforest"
              />
            </div>
            <div class="card-body">
              <div class="mb-5">
                <img
                  style="width: 130px"
                  :src="require(`../../assets/${dataES.cardTwo.img}`)"
                  alt="Icono de puente resaltando union entre emprendimientos e inversionistas"
                />
              </div>

              <input
                class="form-control wet-form fw-bold"
                type="text"
                v-model="dataES.cardTwo.text"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-4 mb-4 regular-card">
        <div class="card shadow-sm puente-cards">
          <div class="regular-card">
            <div class="text-center">
              <img
                class="card-img img"
                :src="require(`../../assets/${dataES.cardThree.img}`)"
                alt="Icono representando inversionistas"
              />
            </div>
            <div class="card-body">
              <input
                class="form-control form-control-lg wet-form card-title fw-bold mb-3 pb-2"
                type="text"
                v-model="dataES.cardThree.title"
              />
              <ckeditor
                :editor="editor"
                v-model="dataES.cardThree.content"
                :config="editorConfig"
              ></ckeditor>
            </div>
          </div>
        </div>
      </div>
    </div>

    <button class="btn btn-success btn-lg mt-3 mb-3" @click="syncContent('ES')">
      Guardar
    </button>

    <div class="w-100">
      <hr class="pill my-5" />
    </div>

    <div class="w-100 d-flex justify-content-start ms-8">
      <h2>
        <span style="color: #14993c"> English </span>
      </h2>
    </div>
    <div class="text-center mb-5">
      <input
        class="form-control form-control-lg wet-form fw-bold text-center mb-2"
        type="text"
        v-model="dataEN.title"
      />

      <input
        class="form-control wet-form text-center"
        type="text"
        v-model="dataEN.subtitle"
      />
    </div>
    <div class="row align-items-center justify-content-center">
      <div class="col-12 col-md-4 mb-4">
        <div class="card shadow-sm puente-cards">
          <div class="regular-card">
            <div class="text-center">
              <img
                class="card-img img"
                :src="require(`../../assets/${dataEN.cardOne.img}`)"
                alt="Imagen apartado emprendimientos"
              />
            </div>
            <div class="card-body">
              <input
                class="form-control form-control-lg wet-form card-title fw-bold mb-3 pb-2"
                type="text"
                v-model="dataEN.cardOne.title"
              />
              <ckeditor
                :editor="editor"
                v-model="dataEN.cardOne.content"
                :config="editorConfig"
              ></ckeditor>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-4 mb-4 green-card-container">
        <div class="card py-5 shadow-sm green-card text-center">
          <div>
            <div class="mb-3">
              <img
                class="card-img img"
                :src="require(`../../assets/${dataEN.cardTwo.logo}`)"
                alt="Logo de wetforest"
              />
            </div>
            <div class="card-body">
              <div class="mb-5">
                <img
                  style="width: 130px"
                  :src="require(`../../assets/${dataEN.cardTwo.img}`)"
                  alt="Icono de puente resaltando union entre emprendimientos e inversionistas"
                />
              </div>

              <input
                class="form-control wet-form fw-bold"
                type="text"
                v-model="dataEN.cardTwo.text"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-4 mb-4 regular-card">
        <div class="card shadow-sm puente-cards">
          <div class="regular-card">
            <div class="text-center">
              <img
                class="card-img img"
                :src="require(`../../assets/${dataEN.cardThree.img}`)"
                alt="Icono representando inversionistas"
              />
            </div>
            <div class="card-body">
              <input
                class="form-control form-control-lg wet-form card-title fw-bold mb-3 pb-2"
                type="text"
                v-model="dataEN.cardThree.title"
              />
              <ckeditor
                :editor="editor"
                v-model="dataEN.cardThree.content"
                :config="editorConfig"
              ></ckeditor>
            </div>
          </div>
        </div>
      </div>
    </div>

    <button class="btn btn-success btn-lg mt-3 mb-3" @click="syncContent('EN')">
      Save
    </button>
  </section>
</template>

<script>
import { getSection, putSection } from "../../helpers/contenido";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default {
  name: "PuenteAdmin",
  data() {
    return {
      dataES: {
        title: "Somos un excelente puente",
        subtitle: "entre emprendedores e inversionistas",
        cardOne: {
          img: "img/beneficio-emprendimiento.webp",
          title: "EMPRENDIMIENTOS",
          content: `
          <p>Talento y Experiencia de Empresarios y Ejecutivos de amplia trayectoria.
           <br><br>Apoyando al emprendedor y su equipo semana a semana.
            <br><br>Desarrollando Talento.
             <br><br>Hablando el lenguaje de los emprendedores.</p>
        `,
        },
        cardTwo: {
          logo: "logo/logo.webp",
          img: "img/img-puente.svg",
          text: "Venture Services",
        },
        cardThree: {
          img: "img/beneficio-inversionista.webp",
          title: "INVERSIONISTA",
          content: `
          <p>Apoyando para maximizar la oportunidad.
           <br><br>Reduciendo el riesgo de la inversión.
           <br><br>Preparando al Emprendimiento para nuevos levantamientos de inversión.</p>
        `,
        },
      },

      dataEN: {
        title: "Somos un excelente puente",
        subtitle: "entre emprendedores e inversionistas",
        cardOne: {
          img: "img/beneficio-emprendimiento.webp",
          title: "EMPRENDIMIENTOS",
          content: `
          <p>Talento y Experiencia de Empresarios y Ejecutivos de amplia trayectoria.
           <br><br>Apoyando al emprendedor y su equipo semana a semana.
            <br><br>Desarrollando Talento.
             <br><br>Hablando el lenguaje de los emprendedores.</p>
        `,
        },
        cardTwo: {
          logo: "logo/logo.webp",
          img: "img/img-puente.svg",
          text: "Venture Services",
        },
        cardThree: {
          img: "img/beneficio-inversionista.webp",
          title: "INVERSIONISTA",
          content: `
          <p>Apoyando para maximizar la oportunidad.
           <br><br>Reduciendo el riesgo de la inversión.
           <br><br>Preparando al Emprendimiento para nuevos levantamientos de inversión.</p>
        `,
        },
      },

      editor: ClassicEditor,
      editorConfig: {
        // The configuration of the editor.
      },
    };
  },
  methods: {
    async fetchContent() {
      const section = await getSection("bridge");
      const sectionES = section.filter((section) => section.language == "ES");
      const sectionEN = section.filter((section) => section.language == "EN");

      this.sectionES = sectionES;
      this.sectionEN = sectionEN;

      this.dataES = sectionES[0].components;

      this.dataEN = sectionEN[0].components;
    },
    async syncContent(lan) {
      if (lan === "ES") {
        const content = {
          id: this.sectionES[0]._id,
          section: "bridge",
          components: this.dataES,
          language: "ES",
        };
        await putSection(content);
      } else {
        const content = {
          id: this.sectionEN[0]._id,
          section: "bridge",
          components: this.dataEN,
          language: "EN",
        };
        await putSection(content);
      }
    },
  },
  watch: {},
  mounted() {
    this.fetchContent();
  },
};
</script>

<style scoped>
.regular-card {
  height: 651px;
}

h6 {
  border-bottom: 1px solid #14993c;
  font-weight: bold;
}

.green-card-container {
  max-width: 290px;
}

.green-card {
  background-color: rgba(32, 178, 76, 0.3);
}

img {
  width: 200px;
}

@media (min-width: 762px) {
  .puente-cards {
    min-height: 550px;
  }
}

hr.pill {
  height: 0px;
  margin: auto auto;
  border-radius: 2px;
  color: #14993c !important;
  border: 2px solid currentColor;
  width: 80%;
}
</style>
